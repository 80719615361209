<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <SubscriptionModal content-id="2"/>
    <div class="container-fluid">
      <div class="row" id="first">
        <div class="col-12">
          <h1>{{ locale[lang].TREVE_ESTIVALE.TOP.TITLE }}</h1>
          <p>
            {{ locale[lang].TREVE_ESTIVALE.TOP.SUBTITLE }}
          </p>
          <button class="btn btn-sc" @click="openModal(locale[lang].MODAL_LINKS.TREVE)">
            {{ locale[lang].TREVE_ESTIVALE.TOP.BUTTON }}
          </button>
        </div>
      </div>

      <div class="row" id="faq">
        <div class="col-12">
          <div class="row">
            <div class="col-12 title" data-aos="fade-right">
              <h1>{{ locale[lang].TREVE_ESTIVALE.QUESTIONS.TITLE }}</h1>
              <h3>{{ locale[lang].TREVE_ESTIVALE.QUESTIONS.SUBTITLE }}</h3>
            </div>
          </div>
          <div class="row justify-content-center" v-for="(text, key) in locale[lang].TREVE_ESTIVALE.QUESTIONS.ITEMS" :key="key" data-aos="fade-right">
            <FaqItem :text="text" :img="img" />
          </div>
        </div>
      </div>

      <div class="row" id="programmes">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <h1>
                {{locale[lang].TREVE_ESTIVALE.PROGRAMMES.TITLE}}
              </h1>
              <p>
                {{locale[lang].TREVE_ESTIVALE.PROGRAMMES.CONTENT.TEXT_1}}
              </p>
              <p>
                {{locale[lang].TREVE_ESTIVALE.PROGRAMMES.CONTENT.TEXT_2}}
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <p>
                {{locale[lang].TREVE_ESTIVALE.PROGRAMMES.CONTENT.TEXT_3}}
              </p>
              <p v-html="locale[lang].TREVE_ESTIVALE.PROGRAMMES.CONTENT.TEXT_4">
              </p>
              <p v-html="locale[lang].TREVE_ESTIVALE.PROGRAMMES.CONTENT.TEXT_5">
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <button class="btn btn-sc" @click="openModal(locale[lang].MODAL_LINKS.TREVE)">
              {{locale[lang].TREVE_ESTIVALE.PROGRAMMES.BUTTON}}
            </button>
          </div>
          <div class="row justify-content-center" id="last">
            <div class="col-12">
              <h5>
                {{locale[lang].TREVE_ESTIVALE.PROGRAMMES.EMAIL}}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="winter">
        <div class="col-12">
          <div class="row justify-content-center" data-aos="fade-right">
            <div class="col-12 title">
              <h1>{{locale[lang].TREVE_ESTIVALE.VOTRE.TITLE}}</h1>
            </div>
          </div>
          <div class="row" id="card">
            <div class="col-12 votre-g">
              <div class="text-votre">
                <img class="img-fluid" src="@/assets/Images/Training/Saison.png" alt="" />
                <p>
                  {{locale[lang].TREVE_ESTIVALE.VOTRE.CONTENT.TEXT_1}}
                </p>
                <p>
                  {{locale[lang].TREVE_ESTIVALE.VOTRE.CONTENT.TEXT_2}}
                </p>
                <p>
                  <span>
                    {{locale[lang].TREVE_ESTIVALE.VOTRE.CONTENT.TEXT_3}}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="row" id="contact">
            <div class="col-12">
              <h4>
                {{locale[lang].TREVE_ESTIVALE.CONTACT.TEXT_1}} <br />
                {{locale[lang].TREVE_ESTIVALE.CONTACT.TEXT_2}}
                <a href="javascript:;" @click="$router.push({ name: 'Contacto' })">{{locale[lang].TREVE_ESTIVALE.CONTACT.LINK}}</a>
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="exercices">
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-12 title" data-aos="fade-right">
              <h1>{{locale[lang].TREVE_ESTIVALE.EXERCICES.TITLE}}</h1>
            </div>
            <p>
              {{locale[lang].TREVE_ESTIVALE.EXERCICES.SUBTITLE}}
            </p>
          </div>
          <div class="row justify-content-center" v-if="!mobile">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row" v-for="(e, key) in exercicesL" :key="key">
                <ExerciceCard :info="e" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row" v-for="(e, key) in exercicesR" :key="key">
                <ExerciceCard :info="e" />
              </div>
            </div>
          </div>
          <div class="row justify-content-center" v-else>
            <ExerciceCardsM :infoCards="infoCards" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import FaqItem from "@/components/FaqItem.vue";
import ExerciceCard from "@/components/ExerciceCard.vue";
import ExerciceCardsM from "@/components/ExerciceCardsM.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
import defaultMixin from "@/mixins/defaultMixin";
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [defaultMixin, modalMixin],
  components: {
    FaqItem,
    ExerciceCard,
    ExerciceCardsM,
    NavBar,
    Footer,
  },
  created() {
    this.exercicesL = this.locale[this.lang].TREVE_ESTIVALE.EXERCICES.E_LEFT;
    this.exercicesR = this.locale[this.lang].TREVE_ESTIVALE.EXERCICES.E_RIGHT;
  },
  computed: {
    infoCards() {
      return this.exercicesL.concat(this.exercicesR);
    },
  },
  data() {
    return {
      mobile: false,
      img: "information@2x-red.png",
      exercicesL: [],
      exercicesR: [],
    };
  },
};
</script>

<style scoped>
h1 {
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
}

#nav-container {
  background-color: #0f1010!important;
}

#first {
  background-image: url("../assets/Images/Training/Trazado 2793.png"),
    url("../assets/Images/Training/Enmascarar grupo 174.png");
  background-size: auto, cover;
  background-repeat: no-repeat, no-repeat;
  text-align: left;
  background-position: 90% 75%, 0 -115px;
  padding: 5% 10% 15% 10%;
}

#first h1,
#programmes h1 {
  text-align: left;
  color: #ffffff;
  margin-bottom: 30px;
}

#first p {
  text-align: left;
  font: normal normal 500 26px/38px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 50px;
  opacity: 1;
}

.btn-sc {
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-color: #0f1010;
  border: 3px solid #ffffff !important;
  font: normal normal bold 22px/27px Montserrat;
  color: #ffffff;
  border-radius: 15px;
  padding: 24px 30px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
  height: 85px;
}

.btn-sc:hover {
  background-color: rgba(75, 75, 75, 0.507);
  margin-left: 20px;
}

#faq {
  background-image: url("../assets/Images/Training/Trazado 2465.png"),
    url("../assets/Images/Training/Elipse 117.png");
  background-size: auto, auto;
  background-repeat: no-repeat, no-repeat;
  background-position: 5% 28%, 92% 90%;
  margin-bottom: 5%;
}

#faq >>> .item {
  width: 1400px;
}

#faq >>> .item h3 {
  text-align: left;
  font: normal normal 500 22px/30px Montserrat;
  letter-spacing: 0.44px;
  color: #242424;
  opacity: 1;
}

#faq .title {
  position: relative;
  margin-bottom: 7%;
}

#faq .title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 48%;
  opacity: 1;
  bottom: -50%;
}

#faq .title h1 {
  text-align: center;
  color: #0f1010;
  margin-bottom: 23px;
}

#faq .title h3,
#exercices p {
  text-align: center;
  font: normal normal 500 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #fc202e;
  opacity: 1;
}

#exercices p {
  color: #0f1010 !important;
}

#programmes {
  background-image: url("../assets/Images/Training/Enmascarar grupo 148.png"),
    url("../assets/Images/Training/Grupo 1103.png");
  background-size: 100% 70%, auto;
  background-repeat: no-repeat, no-repeat;
  background-position: 0 0, 55% 100%;
  padding: 18% 10% 15% 10%;
}

#programmes p {
  text-align: left;
  font: normal normal 500 22px/34px Montserrat;
  letter-spacing: 0.44px;
  color: #ffffff;
  opacity: 1;
}

#programmes p >>> span {
  font-weight: 800 !important;
}

#programmes button {
  margin-top: 60px;
}

#programmes h5 {
  text-align: center;
  font: normal normal 600 32px/44px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
  margin-top: 15%;
}

#winter {
  background-image: url("../assets/Images/Training/Trazado 2677.png"),
    url("../assets/Images/Training/Enmascarar grupo 149.png"),
    url("../assets/Images/Training/Grupo 980.png"),
    url("../assets/Images/Training/Elipse 187.png"),
    url("../assets/Images/Training/Trazado 2687.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 100% 103%, 100% 95%, 7% 0, 11.5% 45%, 98% 35%;
  padding: 0 15% 15% 15%;
}

#winter h1 {
  color: #0f1010;
  margin-bottom: 8%;
}

#winter .title,
#exercices .title {
  position: relative;
}

#winter .title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 48%;
  opacity: 1;
  bottom: 45%;
}

#winter h4 {
  text-align: center;
  font: normal normal 600 35px/50px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 28%;
}

#winter h4 a {
  color: #f9334a;
}

#card {
  padding: 0 4%;
}

.votre-g {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 30px;
  opacity: 1;
}

.text-votre {
  padding: 45px;
}

.text-votre p {
  text-align: center;
  font: normal normal 500 22px/40px Montserrat;
  letter-spacing: 0.44px;
  color: #242424;
  margin-bottom: 35px;
}

.text-votre p >>> span {
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.56px;
}

.text-votre img {
  margin-bottom: 40px;
}

#exercices {
  padding: 0 10%;
  background-image: url("../assets/Images/Training/Grupo 1042.png"),
    url("../assets/Images/Training/Enmascarar grupo 150.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 7% 95%, 100% 65%;
}

#exercices h1 {
  color: #0f1010;
  margin-top: 2%;
  margin-bottom: 5%;
}

#exercices .title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 48%;
  opacity: 1;
  bottom: 30%;
}

@media (max-width: 1700px) {
  #first {
    background-position: 90% 75%, 0 -50px;
    padding: 5% 5% 15% 5%;
  }

  #faq .title::after {
    bottom: -30%;
  }

  #faq >>> .item {
    width: 1100px;
  }

  #exercices .title::after {
    bottom: 20%;
  }

  #programmes {
    padding: 22% 10% 20% 10%;
  }

  #programmes h5 {
    margin-top: 13%;
  }
}

@media (max-width: 1600px) {
  #programmes h5 {
    margin-top: 15%;
  }
}

@media (max-width: 1560px) {
  #programmes h5 {
    margin-top: 16%;
  }
}

@media (max-width: 1500px) {
  #programmes h5 {
    margin-top: 18%;
  }
}

@media (max-width: 1492px) {
  #programmes h5 {
    margin-top: 15%;
  }
}

@media (max-width: 1470px) {
  #programmes h5 {
    margin-top: 13%;
  }
}

@media (max-width: 1280px) {
  #programmes h5 {
    margin-top: 15%;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 18px;
    line-height: 22px;
  }

  #first {
    background-image: url("../assets/Images/Training/Enmascarar grupo 160.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 -50px;
    padding: 0% 5% 15% 5%;
  }
  #first h1 {
    text-align: center;
    font-size: 22px;
    line-height: 27px;
  }

  #first p {
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
  }

  #first .btn-sc {
    display: none;
  }

  #faq .title h1 {
    font-size: 20px;
    color: #000000 !important;
  }

  #faq {
    background-size: 15%;
  }

  #faq .title::after {
    bottom: -15%;
    left: 43%;
  }

  #faq .title {
    padding: 0 5%;
    margin-bottom: 53px;
  }

  #faq .title h3 {
    font-size: 15px;
    line-height: 20px;
    color: #ff0313;
  }

  #faq >>> .item {
    width: 450px;
    padding: 16px 20px 18px 12px;
  }

  #faq >>> .item h3 {
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    color: #080808;
    margin-bottom: 0;
  }

  #faq >>> .item img {
    width: 31px;
    height: 31px;
    margin-right: 10px;
  }

  #programmes {
    background-image: url("../assets/Images/Training/Enmascarar grupo 223.png");
    background-size: 100% 93%;
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 16% 5% 13%;
  }

  #programmes h1 {
    text-align: center;
  }

  #programmes p {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }

  #programmes button {
    font-size: 13px;
    line-height: 16px;
    padding: 15px 14px;
    margin-top: 30px;
  }

  #programmes h5 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin-top: 15%;
  }

  #programmes #last .col-12 {
    padding: 0;
  }

  #winter {
    background-image: url("../assets/Images/Training/Enmascarar grupo 224.png");
    background-repeat: no-repeat;
    background-size: 100% 30%;
    background-position: 100% 92%;
    padding: 0 4% 25% 4%;
  }

  #winter h1 {
    margin-bottom: 60px;
  }

  #winter .title::after {
    left: 40%;
    bottom: 35%;
  }

  .text-votre,
  .votre-g {
    padding: 0;
  }

  .text-votre img {
    margin-bottom: 25px;
  }

  .text-votre p,
  .text-votre p >>> span {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #000000;
    font-weight: 500;
    margin: 0 10px 20px 10px;
  }

  #winter h4 {
    font: normal normal 500 16px/18px Montserrat;
    letter-spacing: 0.12px;
    margin-top: 35%;
  }

  #winter h4 br {
    display: none;
  }

  #exercices {
    background-size: 15%;
    padding: 5% 7% 10% 7%;
  }

  #exercices .title {
    padding: 0;
    position: relative;
  }

  #exercices .title::after {
    left: 39%;
    bottom: 25%;
  }

  #exercices h1 {
    margin-bottom: 40px;
  }

  #exercices p {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.12px;
    color: #151515 !important;
  }

  #exercices >>> .row {
    justify-content: center;
  }

  #exercices >>> .card-title {
    font-size: 15px;
    line-height: 18px;
  }

  #exercices >>> .card-sub {
    font-size: 14px;
  }

  #exercices >>> .card-text p {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  #first {
    padding: 0% 5% 35% 5%;
  }

  #faq >>> .item {
    width: 343px;
    padding: 16px 20px 18px 12px;
  }

  #winter {
    background-position: 108% 100%;
    background-size: 100%;
    padding: 0 4% 21% 4%;
  }

  #programmes {
    background-size: 100% 100%;
    padding: 16% 5% 18%;
  }

   #programmes h5 {
    margin-top: 30%;
  }
}

@media (max-width: 375px) {
  #programmes h5 {
    margin-top: 40%;
  }

  #programmes {
    background-size: 100% 96%;
    padding: 30% 5% 38% 5%;
  }
}
</style>
