<template>
  <div class="card">
    <img
      class="card-img-top"
      :src="require('@/assets/Images/Saison/' + cardInfo.img)"
      alt="Img-card"
    />
    <div class="card-body">
      <h3 class="card-title">{{ cardInfo.title }}</h3>
      <h5 class="card-sub">{{ cardInfo.sub }}</h5>
      <div class="card-text" v-html="cardInfo.text"></div>
      <transition name="fadeHeight" mode="out-in">
        <div
          class="card-text"
          v-html="cardInfo.hide"
          v-if="cardInfo.open"
        ></div>
      </transition>
      <a
        class="card-hide btn btn-hide"
        :class="{ show: cardInfo.open }"
        @click="open"
      >
        <img
          class="arrow"
          src="@/assets/Images/Formation/Icon awesome-chevron-down.png"
          alt="icon-arrow"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardInfo: Object,
    id: Number,
  },
  methods: {
    open() {
      this.$emit("open", this.id);
    },
  },
};
</script>

<style scoped>
.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000014;
  border-radius: 30px;
  opacity: 1;
  border: none;
}

.card-title {
  text-align: center;
  font: normal normal 800 21px/25px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.card-sub {
  text-align: center;
  font: normal normal 500 22px/35px Montserrat;
  letter-spacing: 0.44px;
  color: #242424;
  opacity: 1;
  margin-bottom: 40px;
}

.card-text >>> p {
  text-align: left;
  font: normal normal 500 18px/28px Montserrat;
  letter-spacing: 0.36px;
  color: #242424;
  opacity: 1;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.5s ease;
  max-height: 100%;
  opacity: 1;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
}

.arrow {
  margin-bottom: 4px;
  transform: none;
  transition: transform 0.5s;
}

.show .arrow {
  transform: rotate(180deg);
}
</style>
