<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row" v-for="(info, key) in infoCards" :key="key">
          <div
            class="option"
            @click.prevent="select(key)"
            :class="{ active: id === key }"
          >
            <h3>{{ title(info.title) }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row" v-if="!category && !collapse">
          <ExerciceCard :info="selected" />
        </div>
        <div class="row" v-if="category && !collapse">
          <NotiCard :artInfo="selected" />
        </div>
        <div class="row" v-if="collapse">
          <CollapseCard
            class="cardS"
            :class="{ open: selected.open }"
            :id="selected.id"
            :cardInfo="selected"
            @open="open"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExerciceCard from "@/components/ExerciceCard.vue";
import NotiCard from "@/components/NotiCard.vue";
import CollapseCard from "@/components/CollapseCard.vue";

export default {
  components: {
    ExerciceCard,
    CollapseCard,
    NotiCard,
  },
  data() {
    return {
      selected: this.infoCards[0],
      id: 0,
    };
  },
  methods: {
    select(id) {
      this.id = id;
      this.selected = this.infoCards[id];
    },
    title(text) {
      if (text.length > 40) {
        return text.substr(0, 40) + "...";
      } else {
        return text;
      }
    },
    open(id) {
      for (const card of this.infoCards) {
        if (id === card.id) {
          if (card.open) {
            card.open = false;
          } else {
            card.open = true;
          }
        }
      }
    },
  },
  props: {
    infoCards: Array,
    category: Boolean,
    collapse: Boolean,
  },
};
</script>

<style scoped>
.option {
  background-color: #eeeeee;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 12px;
  padding: 10px;
}

.option.active {
  border: 1px solid #ff0313;
}

.option h3 {
  text-align: left;
  font: normal normal bold 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0;
}

.option.active h3 {
  color: #f8394f;
}

.cardS >>> .card-title {
  font: normal normal 800 12px/15px Montserrat;
}

.cardS >>> .card-sub {
  font: normal normal 500 12px/35px Montserrat;
  letter-spacing: 0.24px;
}

.cardS >>> .card-text p {
  font: normal normal 500 12px/18px Montserrat;
  letter-spacing: 0.24px;
}
</style>
