<template>
  <div class="card">
    <img
      class="card-img-top"
      :src="require('@/assets/Images/' + info.img)"
      alt="Img-card"
      v-if="info.img"
    />
    <div class="card-body">
      <h3 class="card-title">
        {{ info.title }}
      </h3>
      <h5 class="card-sub">{{ info.sub }}</h5>
      <div class="card-text" v-html="info.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
};
</script>

<style scoped>
.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000014;
  border-radius: 30px;
  border: none;
  width: 95%;
  margin-top: 5%;
}

.card-img-top {
  border-radius: 30px 30px 0 0;
}

.card-body {
  padding: 5% 7% !important;
}

.card-title {
  text-align: left;
  font: normal normal bold 21px/25px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.card-sub {
  text-align: left;
  font: normal normal 500 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #ff0313;
  opacity: 1;
  margin-bottom: 5%;
}

.card-text >>> p {
  text-align: left;
  font: normal normal 500 18px/26px Montserrat;
  letter-spacing: 0px;
  color: #aeaeae;
  opacity: 1;
}

.card-text >>> .last {
  margin-top: 25px;
}

.card-text >>> h5 {
  text-align: left;
  font: normal normal bold 20px/30px Montserrat;
  letter-spacing: 0px;
  color: #181818;
  opacity: 1;
  margin-bottom: 25px;
}

.card-text >>> .fa-check {
  color: #f8394f;
}

@media (max-width: 825px) {
  .card {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .card-title {
    font-size: 12px;
    line-height: 15px;
  }

  .card-text >>> p {
    font-size: 10px;
    line-height: 20px;
  }

  .card-sub {
    font-size: 12px;
    line-height: 19px;
  }
}
</style>
