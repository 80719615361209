<template>
  <div class="sm-item">
    <div class="item px-3 py-2 d-flex align-items-center overflow-hidden">
      <img class="img-fluid" :src="require('@/assets/Images/' + img)" alt="info-img" />
      <h3 class="overflow-hidden">{{ text }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    img: String,
  },
};
</script>

<style scoped>
.sm-item {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #0000001a;
  border-radius: 15px;
  opacity: 1;
  margin-bottom: 30px;
  cursor: pointer;
}

h3 {
  text-align: left;
  font: normal normal bold 26px/32px Montserrat;
  color: #080808;
  opacity: 1;
}

img {
  height: 63px;
  width: 63px;
  margin-right: 50px;
}

@media screen and (max-width: 720px) {
  h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>